import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Table, Modal, Radio, Popconfirm, Select, Input, Tag, DatePicker, InputNumber } from 'antd';
import { useParams } from "react-router-dom";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueProvider';
import dayjs from 'dayjs';
import { render } from '@testing-library/react';
import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);

const { RangePicker } = DatePicker;
const { Option } = Select;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};


const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledHours: () => range(0, 60).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledHours: () => range(0, 60).splice(20, 4),
        disabledMinutes: () => range(0, 31),
        disabledSeconds: () => [55, 56],
    };
};

const SearchForm = ({ queryList, clickAdd, store }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        initialValues={{}}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >

        <Row gutter={24}>
            <Col xs={14} sm={4} md={6} lg={8} xl={6}>
                <Form.Item name="type">
                    <Select >
                        {store.map(e => (
                            <Option value={e.event_id} key={e.event_id}>{e.event_name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={10} sm={4} md={6} lg={8} xl={2}>
                <Form.Item name="status">
                    <Radio.Group>
                        <Radio value={1}>进行中</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button type="primary" htmlType="submit">搜索</Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button
                    onClick={() => {
                        formSearch.resetFields();
                    }}
                >
                    清空
                </Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={12} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => clickAdd()}>
                    添加计划
                </Button>
            </Col>
        </Row>
    </Form>
};

export default function EventPlan() {

    let { app } = useParams();
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0); //记录的id
    const [formAdd] = Form.useForm();
    const [store, setStore] = useState([]);
    const [store2, setStore2] = useState([]);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    const { token, makeRequest } = useSharedValues();
    const [connected, setConnected] = useState(false);
    const p404 = Use404();

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback((values) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event`, values, setDs, setLd)
    }, [app, makeRequest])

    const queryStore = useCallback(() => {
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/devices/store`, null, setStore, setLd)
    }, [app, makeRequest])
    const queryStore2 = useCallback(() => {
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events/store`, null, setStore2, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `EventPlan`;
            queryList({});
            queryStore();
            queryStore2();
        }
    }, [p404, queryList]);

    useEffect(() => {
        if (!connected) {
            const r = ds.find(r => r.pending);
            if (r) {
                setConnected(true);
                var eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/modp/r/${app}/config/sse/${r.id}?Auth=${token}`);
                eventSource.onopen = function () {
                    console.log('sse connected');
                };
                eventSource.onmessage = function (event) {
                    eventSource.close();
                    queryList({}, true)
                    // setConnected(false)
                    // updateReleaseDate(r.id, event.data)
                };
                eventSource.onerror = function () {
                    console.log('sse closed')
                    eventSource.close();
                    setConnected(false)
                };
            }
        }
    }, [ds])

    if (p404) {
        return p404;
    }

    const clickAdd = (r) => {
        if (r) {
            setId(r.id);
            formAdd.setFieldsValue({
                type: r.type,
                category: r.category,
                exclude: r.exclude,
                restraint: r.restraint,
                range_time: [dayjs.utc(r.start_time).local(), dayjs.utc(r.end_time).local()]
            })
        } else {
            setId(0);
            formAdd.setFieldsValue({
                type: '',
                category: '',
                exclude: [],
                restraint: [],
                range_time: null
            })
        }
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const create = (values) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event`, values, okfn, setLd);
    };
    const update = (values) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event/${id}`, values, okfn, setLd);
    };

    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event/${id}`, null, okfn, setLd)
    }
    const cancelfn = (id) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event/cancel/${id}`, null, okfn, setLd)
    }
    const abortfn = (id) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/event/abort/${id}`, null, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 86,
        },
        {
            title: '活动',
            // dataIndex: 'type',
            width: 86,
            render: (_, r) => {
                let event = store2.find(e => e.event_id === r.type)
                return event ? event.event_name : r.type
            }
        },
        {
            title: '活动小类',
            dataIndex: 'category',
            width: 86,
        },
        {
            title: '排除版本',
            dataIndex: 'exclude',
            width: 185,
            render: (_, r) => {
                if (r.exclude) {
                    return <>
                        {r.exclude.map((str, index) => (
                            <Tag ordered={false}>{str}</Tag>
                        ))} </>
                }
            }
        },
        {
            title: '限制设备',
            dataIndex: 'exclude',
            width: 160,
            render: (_, r) => {
                if (r.restraint) {
                    return <>
                        {r.restraint.map((str, index) => (
                            <Tag ordered={false}>{str}</Tag>
                        ))} </>
                }
            }
        },
        {
            title: '开始时间',
            width: 160,
            dataIndex: 'start_time',
        },
        {
            title: '结束时间',
            width: 160,
            dataIndex: 'end_time',
        },
        {
            title: '创建时间',
            width: 160,
            dataIndex: 'create_time',
        },
        {
            title: '状态',
            width: 150,
            dataIndex: 'status',
            render: (_, r) => {
                if (!(r.status)) {
                    r['status'] = 0
                }
                switch (r.status) {
                    case 0:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">更新中</Tag>
                        }
                        return <Tag icon={<ClockCircleOutlined />} color="default">计划中</Tag>
                    case 1:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">更新中</Tag>
                        }
                        return <Tag icon={<CheckCircleOutlined />} color="success">活动进行中</Tag>
                    case 2:
                        return <Tag icon={<MinusCircleOutlined />} color="default">已结束</Tag>
                    case 3:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">取消中</Tag>
                        }
                        return <Tag icon={<MinusCircleOutlined />} color="warning">已取消</Tag>
                    case 4:
                        if (r.pending) {
                           return <Tag icon={<SyncOutlined spin />} color="processing">终止中</Tag>
                        }
                        return <Tag icon={<CloseCircleOutlined />} color="error">已终止</Tag>
                    default:
                        return <>未知状态</>
                }
            }
        },
        {
            title: '操作',
            width: 224,
            render: (_, r) => {
                if (r.pending) {
                    return <></>
                }
                if (!(r.status)) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0: //新建
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要取消此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => cancelfn(r.id)}
                                >
                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Button type="dashed" danger onClick={() => clickAdd(r)}>
                                    修改
                                </Button>
                            </Col>
                        </Row>
                    case 1: //发布中
                        return <Row gutter={8} justify="start">

                            <Col>
                                <Popconfirm title="确定要终止此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => abortfn(r.id)}
                                >
                                    <Button type="dashed" danger>
                                        终止
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Button type="dashed" danger onClick={() => clickAdd(r)}>
                                    修改
                                </Button>
                            </Col>
                        </Row>
                    default:
                        return <></>
                }
            },
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} store={store2} />

            <Modal
                title="活动"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    formAdd.submit()
                }}
            >
                <div>
                    <Form
                        form={formAdd}
                        layout="vertical"
                        onFinish={id === 0 ? create : update}
                    >

                        <Form.Item
                            name="type"
                            label="活动类型"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择活动类型',
                                },
                            ]}
                        >
                            <Select disabled={id !== 0}>
                                {store2.map(e => (
                                    <Option value={e.event_id} key={e.event_id}>{e.event_name}</Option>
                                ))}
                            </Select>

                        </Form.Item>

                        <Form.Item
                            name="category"
                            label="活动小类"
                        >
                            <InputNumber min={0} step={1} precision={0} />
                        </Form.Item>

                        {/* <Form.Item
                        name="remark"
                        label="内容">
                        <TextArea rows={3} />
                    </Form.Item> */}

                        <Form.Item
                            name="range_time"
                            label="时间"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择时间',
                                },
                            ]}>
                            <RangePicker
                                disabledDate={disabledDate}
                                // disabledTime={disabledRangeTime}
                                showTime={{
                                    hideDisabledOptions: true,
                                    defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')],
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>

                        <Form.Item
                            name="exclude"
                            label="排除版本"
                        >
                            <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                tokenSeparators={[',']}
                            />
                        </Form.Item>

                        <Form.Item
                            name="restraint"
                            label="限制设备"
                        >
                            <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="空表示无限制"
                            >
                                {store.map(e => (
                                    <Option value={e.device_id} key={e.device_id}>{e.device_name}</Option>
                                ))}

                            </Select>
                        </Form.Item>

                    </Form>

                </div>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '68vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
